
html, body, .grid-container {
    height: 100%;
    margin: 0;
    background-color: rgb(246,246,246);
    font-family: sans-serif;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 1.4fr;
  gap: 1px 1px;
  grid-template-areas:
    "logopane"
    "mainpane";
}

.logopane {
  display: grid;
  grid-template-columns: 0.3fr 1.7fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  vertical-align: middle;
  grid-template-areas:
    ". searchpane";
  grid-area: logopane;
}

.searchpane {
  display: grid;
  grid-template-columns: 1.7fr 0.3fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas:
    ". profilepane";
  grid-area: searchpane;
}

.profilepane { grid-area: profilepane; }

.mainpane {
  display: grid;
  height: 100%;
  grid-template-columns: 0.3fr 1.7fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas:
    "menupane contentpane";
  grid-area: mainpane;
}

.contentpane {
    grid-area: contentpane;
    height: 100%;
    padding: 10px;
}

.menupane {
    grid-area: menupane;
    background-color: rgb(21,67,108);
    height: 100%;
    padding: 0;
}

.menupane img {
    height: 25px;
    width: 25px;
    vertical-align: middle;
}

.menupane ul {
  display: inline;
  list-style-type: none;
  margin: 0;
  width: 100%;
}

.menupane li a {
  color: #FFF;
  font-size: 11px;
  font-weight: normal;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  border-bottom: 3px solid #A0A0A0;
  width: 75%;
}

.active {
  background-color: #0099FF;

}

/**
.grid-container * {
  border: 1px solid red;
  position: relative;
  }

.grid-container *:after {
  content:attr(class);
  position: absolute;
  top: 0;
  left: 0;
  }
**/